<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <b-row>
        <b-col md="9">
          <b-card>
            <h4>
              <strong>Howdy Administrator!</strong>
            </h4>
            <p>
              Welcome to the Diametral Involute Website Administrator Page
              You are on the PROTECTED page of the Diametral Involute Website Content Management system.
            </p>
            <ol>
              <li>Save and secure your Username and Password to avoid things that are not desirable.</li>
              <li>Prepare the material or content that will be updated first.</li>
              <li>Also prepare photos, videos or other related material, to facilitate the updating process.</li>
              <li>Use the latest version of the browser to get the compatibility features in this backoffice.</li>
            </ol>
            <p>
              Web Developer Contact:<br>
              Email: bara@lingkar9.com | hello@lingkar9.com <br>
              Phone: +62-8193-0962-159
            </p>
          </b-card>
        </b-col>
        <b-col md="3">
          <div class="card_dashboard bg_card_1 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.event}}</h3>
                  <p>Event Published</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_2 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.news}}</h3>
                  <p>News Published</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="icon-briefcase"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.vacancies}}</h3>
                  <p>Open Vacancies</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="6">
          <b-card no-body :header-class="dataEvent.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Most Viewed Event</h5>
                <b-button class="btn-rounded" variant="info" 
                v-if="dataEvent.length"
                :to="{ name: 'BoEvent'}"
                >
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
						<b-table
							responsive
							:fields="blogFields"
							:items="dataEvent"
							striped
							bordered
							class="mb-0"
							show-empty
						>
						<template #cell(num)="data">{{ data.index += 1 }}</template>
							<template #cell(title)="data">
								<div class="wrap_title_post">
									<h3>
										<a href="javascript:;">{{ data.item.ae_title_id }}</a>
									</h3>
								</div>
							</template>
							<template #cell(view)="data">
								<i class="icon-eye"></i> {{data.item.ae_count_of_view}}
							</template>
						</b-table>
          </b-card>
        </b-col>
        <b-col lg="6">          
          <b-card no-body :header-class="dataNews.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Most Viewed News</h5>
                <b-button class="btn-rounded" variant="info"
                  :to="{ name: 'BoBlog' }"
                  v-if="dataNews.length"
                >
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
						<b-table
							responsive
							:fields="blogFields"
							:items="dataNews"
							striped
							bordered
							class="mb-0"
							show-empty
						>
							<template #cell(num)="data">{{ data.index += 1 }}</template>
							<template #cell(title)="data">
								<div class="wrap_title_post">
									<h3>
										<a href="javascript:;">{{ data.item.ab_title_id }}</a>
									</h3>
								</div>
							</template>
							<template #cell(view)="data">
								<i class="icon-eye"></i> {{data.item.ab_count_of_view}}
							</template>
						</b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
			dataCard:{},
			dataEvent:[],
			dataNews:[],	
			blogFields: [
				{ key: 'num', label: '#' },
				{ key: 'title'},
        { key: 'view', label: 'Total View'}
			],
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  },
}
</script>